import React, {useEffect, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {getTestName} from 'src/shared/reactstrap-toolbox'
import {Button} from 'reactstrap'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

const SuccessPage = () => {
  const {id: bid, test_type} = useParams()
  const [bookingId, setBookingId] = useState()
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('')
  const [status, setStatus] = useState()

  const fetchDetails = async () => {
    const url = `/api/dashboard/registration/details/?bid=${bid}`
    const response = await window.app.requests.get(url, null, {
      expected_status: [200, 400],
    })
    if (response.status !== 200) {
      setError(response.data.message || response.data.details?.description)
    }
    setBookingId(response.data.personal.booking_id)

    if (response.data.personal.status) {
      setStatus(response.data.personal.status)
    }
  }

  useEffect(() => {
    fetchDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="pt-sm-4 pl-sm-2 pl-md-2 mt-4">
      <h1>APPOINTMENT CONFIRMATION</h1>
      <div className="row justify-content-center py-5">
        <div className="col-md-12">
          <h3>Success!</h3>
          <p>
            Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is now complete.
          </p>
          <hr />
          <h3>Test type: {getTestName(getTestTypeValueFromUrl(test_type))} </h3>
          <p>
            When you arrive for your appointment, please provide the health professional with registration number{' '}
            <strong>{bookingId}.</strong>
          </p>
          <p>You can edit your registration details until the day of your appointment.</p>
          <Button
            className="mt-2 text-white font-weight-bold"
            tag={Link}
            to={`/bloods/boots/assessment-single/${getTestTypeValueFromUrl(test_type)}/${bid}/`}
            color="primary"
          >
            {(status !== null || status !== null) && status !== 'created' ? 'Edit details' : 'View details'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessPage
