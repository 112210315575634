import React, {useEffect, useState} from 'react'
import {Alert, Container} from 'reactstrap'
import {useHistory, useParams} from 'react-router-dom'
import {LinkCard} from 'src/shared/LinkCard'
import Boots from 'src/assets/images/Vector.svg'
import {ContinueJourney} from './ContinueJourney'
import {getTestTypeValueFromUrl} from '../../assessment_utils'

// Activate view -Boots Booking to continue
function Dashboard() {
  const {test_type} = useParams()
  const history = useHistory()
  const [untestable, setUntestable] = useState()
  const [resume, setsetResume] = useState()

  // Fetch data for pending journey if any
  const fetchSummary = async () => {
    const response = await window.app.requests.get(`/api/dashboard/registration/summary/`, null, {
      expected_status: [200, 422, 404],
    })
    setUntestable(response.data.latest_test.lab_failed)
  }

  const fetchBookings = async () => {
    const response = await window.app.requests.get(`/api/dashboard/registration/bookings/`, null, {
      expected_status: [200, 422, 404],
    })
    setsetResume(response.data.bookings[0])
  }

  // Fetch data for pending journey if any
  useEffect(() => {
    fetchBookings()
  }, [])

  // Fetch data for untestable samples if any
  useEffect(() => {
    (resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') && fetchSummary()
  }, [resume])

  if (
    (resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') &&
    getTestTypeValueFromUrl(test_type) === resume.test_type
  ) {
    resume.status !== 'created' &&
      history.push(`/bloods/boots/success-page/${getTestTypeValueFromUrl(test_type)}/${resume.id}`)
  }

  return (
    <div>
      <Container className="mb-5">
        <div className="pt-sm-4 pl-sm-2 pl-md-2 mt-4">
          {getTestTypeValueFromUrl(test_type) === 'unknown' ? (
            <Alert color="danger">You've received an invalid URL. Please verify and try again.</Alert>
          ) : (
            <></>
          )}
          {!untestable &&
          (((getTestTypeValueFromUrl(test_type) !== resume?.test_type ||
            (getTestTypeValueFromUrl(test_type) === resume?.test_type && resume.status === 'created')) &&
            getTestTypeValueFromUrl(test_type) !== 'unknown') ||
            !resume) ? (
            <div className="text-left">
              <h1 className="text-left py-5">MY REGISTRATION</h1>
              <LinkCard
                to={`/bloods/boots/registration/${getTestTypeValueFromUrl(test_type)}`}
                image={Boots}
                title="Register your Boots store appointment"
                button_text="Continue"
              >
                <div className="text-muted">Boots In-store Wellness Testing Service powered by MyHealthChecked</div>
              </LinkCard>
            </div>
          ) : (
            getTestTypeValueFromUrl(test_type) !== 'unknown' && (
              <ContinueJourney
                untestable={untestable}
                resume={resume}
                test_type={
                  getTestTypeValueFromUrl(test_type) !== 'unknown'
                    ? getTestTypeValueFromUrl(test_type)
                    : resume.test_type
                }
              />
            )
          )}
        </div>
      </Container>
    </div>
  )
}

export default Dashboard
