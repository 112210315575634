import React from 'react'
import {Button} from 'reactstrap'

var resume_link

export const ContinueJourney = props => {
  switch (props.resume?.registration_stage) {
    case 'type-confirm':
      resume_link = `/bloods/boots/consent/${props.test_type}/${props.resume.id}/`
      break
    case 'consent':
      resume_link = `/bloods/boots/user-details/${props.test_type}/${props.resume.id}/`
      break
    case 'personal-details':
      resume_link = `/bloods/boots/assessment-form/${props.test_type}/${props.resume.id}/1/`
      break
    default:
      break
  }

  return (
    <div className="container">
      <h1>ACTION REQUIRED</h1>
      <hr />

      {props.untestable && (
        <div className="mt-4">
          <div>
            <p>
              We regret to inform you that our partner lab was unable to successfully analyze your sample(s). This may
              happen due to several reasons such as leakage during transit, clotted samples, or heamolysis.
            </p>
            <p>To book a new appointment, please click on the button below.</p>
          </div>
          <Button color="primary" href="https://www.boots.com/wellnesstestrebook" target="_blank">
            Rebook Appointment
          </Button>
        </div>
      )}
      {props.resume && (
        <div className="mt-4">
          <h4>
            Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is not yet
            complete.
          </h4>

          <p>Please click the button below to continue your registration.</p>
          <Button color="primary" href={resume_link}>
            Continue Registration
          </Button>
        </div>
      )}
    </div>
  )
}
